import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';

import { Container, HeroContainer, Section } from 'components/Container';

import { FlexibleLinkComponent, GatsbyLink } from 'components/Links';
import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const PrivacyCollectionNotice: FC<LocalizedPageProps> = () => (
  <Layout>
    <Seo />
    <HeroContainer background="cream" skipColoration>
      <Section>
        <h1>Privacy Collection Notice</h1>
      </Section>
    </HeroContainer>
    <Container>
      <Section>
        <p>
          This Privacy Collection Notice describes how Sharesight Pty Ltd (ABN 36 151 146 726) (we,
          us or our) collects and handles your personal information when you provide us with your
          demographic information and/or complete our surveys. We collect personal information from
          you and from third parties (such as our cookies and analytics providers) so that we can
          conduct analytics (including profiling on our website), market research and business
          development, including for the operation and improvement of our services, and for related
          purposes set out in our <GatsbyLink to="privacy-policy">Privacy Policy</GatsbyLink>,
          available on our website (or on request).
        </p>
        <p>
          We may disclose this personal information to third parties, including our personnel,
          related entities, any third parties engaged by us and acting on our behalf and as
          otherwise set out in our <GatsbyLink to="privacy-policy">Privacy Policy</GatsbyLink>.
        </p>
        <p>
          If you do not provide your personal information to us, it may affect our ability to do
          business with you.
        </p>
        <p>
          Please see our <GatsbyLink to="privacy-policy">Privacy Policy</GatsbyLink> for more
          information about how we collect, store, use and disclose your personal information,
          including details about overseas disclosure, access, correction, how you can make a
          privacy-related complaint and our complaint-handling process.
        </p>
        <p>
          If you have questions about our privacy practices, please contact us by email at:{' '}
          <FlexibleLinkComponent to="mailto:support@sharesight.com">
            support@sharesight.com
          </FlexibleLinkComponent>
          . By providing your personal information to us, you agree to the collection, use, storage
          and disclosure of that information as described in this privacy collection notice.
        </p>
      </Section>
    </Container>
  </Layout>
);

export default PrivacyCollectionNotice;
